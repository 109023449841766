<template>
  <div receiver-bank-id>
  </div>
</template>

<script>
export default {
  name: 'ReceiverBankId',
  mounted() {
    const { Code, StateValue, Error, ErrorDescription } = parseQuery(location.search);
    console.log('bankId result ::: ', Code, StateValue, Error, ErrorDescription);

    this.replaceRouteName('StartOnboarding', null, { Code, StateValue, Error, ErrorDescription });
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>